import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "preact/hooks";
import { Circle, MapContainer, ScaleControl, Tooltip, ZoomControl, useMap } from "react-leaflet";
import { MarkerClusterGroup, ProtoLayer } from "../utils/leaflet-extensions";
import { Post } from "./post";
import { MenuItem, PageProps, PostData } from "./types";

const ChangeView = ({ center, zoom }: { center: LatLngExpression; zoom: number }) => {
  const map = useMap();
  map.setView(center, zoom, { animate: true, duration: 0.5 });
  return null;
};

export const Photoblog = ({ onPageMounted, pageMenuItem, onMenuItemChanged }: PageProps) => {
  const [content, setContent] = useState<PostData[]>([]);
  const [menuItem, setMenuItem] = useState<MenuItem | undefined>({ name: "Tous" });
  const [limit, setLimit] = useState<number>(5);
  const [zoom] = useState<number>(9);
  const [showedPost, setShowedPost] = useState<PostData | undefined>(undefined);
  const [center, setCenter] = useState<[number, number]>([47.2501129, -1.5743799]);
  const [scrollToAfterLoading, setScrollToAfterLoading] = useState<PostData>();

  const scrollTo = (post: PostData, index: number) => {
    const elt = document.getElementById(post.id);
    if (elt) {
      elt.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
    } else {
      setLimit(index + 3);
      setScrollToAfterLoading(post);
    }
  };

  useEffect(() => {
    const scroller = document.getElementById("scroller");
    if (scroller) {
      scroller.scrollTo({ top: 0, behavior: "smooth" });
    }
    const menuBtn: HTMLInputElement = document.getElementById("menu-btn") as HTMLInputElement;
    if (menuBtn) {
      menuBtn.checked = false;
    }
    setMenuItem(pageMenuItem);
  }, [pageMenuItem]);

  const onPostVisible = (post: PostData, index: number) => {
    setShowedPost(post);
    setCenter([post.latitude, post.longitude]);
    setLimit(
      limit - index < 3 // si plus assez de posts, on en rajoute
        ? limit + 5
        : limit,
    );
    /*const timeout = setTimeout(() => {
        setCenter([post.latitude, post.longitude]);
        setLimit(
          limit - index < 3 // si plus assez de posts, on en rajoute
            ? limit + 5
            : limit,
        );
      }, 250);
  
      return () => clearTimeout(timeout);*/
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("content/photos/photos.json");
      if (!response.ok) return;
      const content: PostData[] = await response.json();

      // https://stackoverflow.com/questions/53542882/es6-removing-duplicates-from-array-of-objects
      const tags: string[] = ["Tous", ...new Set<string>(content.map((a) => a.tags).reduce((a, b) => [...(a || []), ...(b || [])], []))];
      content.forEach((post) => {
        post.images?.forEach((image) => {
          image.src = "content/photos/" + image.src;
          image.thumb = "content/photos/" + image.thumb;
        });
      });
      setContent(content);
      const finalMenus: MenuItem[] = [];
      const Ailleurs: MenuItem = {
        name: "Ailleurs",
        children: [],
      };
      tags.forEach((tag) => {
        if (tag.match(new RegExp("(tous)|(france)|(velo)", "i"))) {
          finalMenus.push({ name: tag });
        } else if (Ailleurs && Ailleurs.children) {
          Ailleurs.children.push({ name: tag });
        }
      });
      finalMenus.push(Ailleurs);
      onPageMounted("Photoblog", finalMenus);
    };
    fetchData();
  }, []);

  return (
    <div className="scroller" id="scroller">
      <div className="scroll__graphic sticky" style={{ width: "40%" }}>
        <div className="overlay">
          <MapContainer id="map" maxZoom={22} dragging={true} scrollWheelZoom={false} center={center} zoom={zoom} zoomControl={false}>
            <ChangeView center={center} zoom={zoom} />
            <ProtoLayer
              position="bottomright"
              options={{ maxDataZoom: 11, url: "https://tiles.shagr.at/world11.pmtiles", theme: "dark" }}
              overlays={["Satellite"]}
            />
            <ZoomControl position="bottomright" />
            <ScaleControl position="bottomright" metric={true} imperial={false} />
            <MarkerClusterGroup>
              {content
                .filter(
                  (post) =>
                    post.latitude &&
                    post.longitude &&
                    menuItem &&
                    (menuItem.name == "Tous" || (menuItem.name && post && post.tags && post.tags.indexOf(menuItem.name) > -1)),
                )
                .map((post, index) => (
                  <Circle
                    key={"circle-" + index}
                    center={[post.latitude, post.longitude]}
                    eventHandlers={{
                      click: () => {
                        scrollTo(post, index);
                      },
                    }}
                    pathOptions={{ color: post === showedPost ? "red" : "gray", fillOpacity: 0.6 }}
                    radius={2000}
                  >
                    <Tooltip permanent={true} interactive={true} direction="right">
                      {post.title}
                    </Tooltip>
                  </Circle>
                ))}
            </MarkerClusterGroup>
          </MapContainer>
        </div>
      </div>
      <div className="scroll__text">
        {content
          .filter(
            (post) =>
              menuItem && (menuItem.name == "Tous" || (menuItem.name && post && post.tags && post.tags.indexOf(menuItem.name) > -1)),
          )
          .filter((_, index) => index < limit)
          .map((post, index) => (
            <Post
              key={index}
              //io={io}
              index={index}
              post={post}
              scrollToAfterLoading={post === scrollToAfterLoading}
              onMenuItemChanged={onMenuItemChanged}
              onScrollToAfterLoading={() => scrollTo(post, index)}
              onShow={() => onPostVisible(post, index)}
            />
          ))}
      </div>
    </div>
  );
};
