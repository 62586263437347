import { gpx } from "@tmcw/togeojson";
import { FeatureCollection } from "geojson";
import { LeafletEvent, Map as LeafletMap, MarkerCluster, Polyline, divIcon, icon } from "leaflet";
import { useEffect, useRef, useState } from "preact/hooks";
import { GeoJSON, MapContainer, Marker, ScaleControl, ZoomControl } from "react-leaflet";
import { MarkerClusterGroup, ProtoLayer } from "../utils/leaflet-extensions";
import { showSpotlight } from "./spotlight";
import { MenuItem, PageProps, TrackData } from "./types";

export const Tracks = ({ onPageMounted, pageMenuItem }: PageProps) => {
  const map = useRef<LeafletMap | null>(null);
  const [menuItem, setMenuItem] = useState<MenuItem | undefined>();
  const [zoom] = useState<number>(9);
  const [center] = useState<[number, number]>([47.2501129, -1.5743799]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("content/tracks/tracks.json");
      if (!response.ok) return;
      const content: TrackData[] = await response.json();
      const finalMenus: MenuItem[] = [...new Set(content.map((c) => c.year))].map((year) => {
        return {
          name: year,
          tracks: [content.filter((c) => c.year == year)[0]],
          children: content
            .filter((c) => c.year == year)
            .map((c) => {
              return {
                name: c.name,
                tracks: [c],
              };
            }),
        };
      });
      finalMenus.push({
        name: "Tous",
        tracks: content,
      });
      onPageMounted("Tracks", finalMenus);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    (async function fetchData() {
      if (pageMenuItem && pageMenuItem.tracks) {
        pageMenuItem.geojsons = await Promise.all(
          pageMenuItem.tracks.map(async (track: TrackData) => {
            const response = await fetch("/content/tracks/" + track.url, { signal: abortController.signal });
            if (!response.ok)
              return {
                type: "FeatureCollection",
                features: [],
              } as FeatureCollection;
            const xml = await response.text();
            return gpx(new DOMParser().parseFromString(xml, "text/xml"));
          }),
        );
      }
      setMenuItem(pageMenuItem);
    })();
    return () => abortController.abort();
  }, [pageMenuItem]);

  const onPhotoClick = (e: LeafletEvent) => {
    showSpotlight([{ id: "1", src: e.target.options.icon.options.pane, description: e.target.options.icon.options.caption }], {
      index: 1,
      control: ["autofit", "zoom", "page", "fullscreen", "prev", "next", "close"],
      theme: "dark",
      autohide: false,
      animation: "fade",
    });
  };

  const gpxLoaded = (g: Polyline) => {
    map.current?.fitBounds(g.getBounds());
  };

  const createClusterCustomIcon = (cluster: MarkerCluster) => {
    return divIcon({
      className: "leaflet-marker-photo",
      html: `<div style="background-image: url(${cluster.getAllChildMarkers()[0].options.icon?.options.iconUrl});"></div><b>${cluster.getChildCount()}</b>`,
      iconSize: [48, 48],
      iconAnchor: [0, 0],
    });
  };

  return (
    <div className="scroller" id="scroller">
      <div className="scroll__graphic sticky" style={{ width: "100%", display: "block" }}>
        <MapContainer
          id="map"
          ref={map}
          maxZoom={22}
          dragging={true}
          scrollWheelZoom={true}
          center={center}
          zoom={zoom}
          zoomControl={false}
        >
          <ProtoLayer
            position="bottomright"
            options={{ maxDataZoom: 15, url: "https://tiles.shagr.at/france.pmtiles", theme: "dark" }}
            overlays={["Satellite"]}
          />
          <ZoomControl position="bottomright" />
          <ScaleControl position="bottomleft" metric={true} imperial={false} />

          {((menuItem && menuItem.geojsons) || []).map((g, index) => (
            <GeoJSON
              eventHandlers={{
                add: (e) => gpxLoaded(e.target),
              }}
              key={"gpx-" + menuItem?.name + "-" + index}
              data={g}
              pathOptions={{ color: "#afeacc", fillOpacity: 0.6 }}
            />
          ))}

          <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
            {(menuItem?.tracks || [])
              .map((a) => a.images)
              .reduce((a, b) => [...a, ...b], [])
              .map((trackPhoto, index) => (
                <Marker
                  key={"photo-" + index}
                  eventHandlers={{ click: onPhotoClick }}
                  position={[trackPhoto.lat, trackPhoto.lon]}
                  icon={icon({
                    iconUrl: "/content/photos/" + trackPhoto.thumb,
                    className: "leaflet-marker-photo",
                    iconSize: [48, 48],
                    iconAnchor: [0, 0],
                    pane: "/content/photos/" + trackPhoto.src,
                  })}
                  title={""}
                />
              ))}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </div>
  );
};
